import { FC, useEffect, useMemo, useState } from "react";
import Modal from "../Modal";
import classNames from "classnames";
import { FormSelect } from "../FormFields";
import { useAppSelector } from "../../store/hooks";
import { TrashIcon } from "@heroicons/react/24/outline";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import {
  alertService,
  getShipmentItems,
  getShipmentRates,
  getTier,
  loaderService,
  updateShipment,
} from "../../services";
import {
  DeliveryType,
  ItemInterface,
  LocationInterface,
  PaymentMode,
  ShipmentInterface,
  ShipmentService,
} from "../../interfaces";
import {
  formatAmount,
  currencyExchangeText,
  useCurrencyExchanges,
} from "../../utils";
import LoadingIcon from "../LodingIcon";
import { AddressSearch } from "../LocationSearch";
import { AutocompleteRegion } from "../Form/FormMapsAutocomplete";

interface OptionalShipmentItemsProps {
  open: boolean;
  shipment: ShipmentInterface;
  selectedShipmentNumbers: string[];
  onRefresh?: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const OptionalShipmentItems: FC<OptionalShipmentItemsProps> = ({
  open,
  shipment,
  selectedShipmentNumbers,
  setOpen,
  onRefresh = () => {},
}) => {
  const exchanges = useCurrencyExchanges();
  const user = useAppSelector((state) => state.user.user);
  const applicationID = useAppSelector(
    (state) => state.inmutable.appData.applicationID
  );
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );

  const loading = loaderService.useIsLoading();
  const [items, setItems] = useState(shipment.items);
  const [locationError, setLocationError] = useState("");
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [allItems, setAllItems] = useState<ItemInterface[]>([]);
  const [deliveryDistance, setDeliveryDistance] = useState<number>();
  const [consigneeAddress, setConsigneeAddress] = useState<LocationInterface>();

  const absoluteTotal = useMemo(() => {
    return items
      .filter((item) => !item.rate.isPercentage)
      .reduce((acc, item) => acc + item.rate.value, 0);
  }, [items]);

  const optionalItems = useMemo(() => {
    return allItems.filter((item) => !items.find((i) => i.id === item.id));
  }, [allItems, items]);

  const showMap = useMemo(() => {
    return (
      items.find((item) => item.id === "21") !== undefined &&
      shipment.items.find((item) => item.id === "21") === undefined
    );
  }, [items, shipment.items]);

  const getAllShipmentRates = async () => {
    if (
      !consigneeAddress ||
      !shipment.totalChargedWeight ||
      !shipment.declaredValue ||
      shipment.deliveryDistance === undefined ||
      !shipment.buSource?.code ||
      !shipment.consigneeAddress?.coordinates.lat ||
      !shipment.consigneeAddress?.coordinates.lng
    ) {
      return;
    }
    const buSource = businessUnits.find(
      (bu) => bu.code === shipment.buSource?.code
    );

    setLoadingOptions(true);
    const tier = await getTier({
      AccountBillToID: shipment.accountBillTo?.id,
      LatitudeShipperAddress: buSource?.location.coordinates.lat,
      LongitudeShipperAddress: buSource?.location.coordinates.lng,
      LatitudeConsigneeAddress: shipment.consigneeAddress?.coordinates.lat,
      LongitudeConsigneeAddress: shipment.consigneeAddress?.coordinates.lng,
    });
    const responseItems = await getShipmentItems({
      CurrencyID: 2,
      CountryID: 236,
      Weight: shipment.totalChargedWeight,
      DeclaratedValue: Number(shipment.declaredValue),
      Distance: shipment.deliveryDistance,
      ApplicationID: applicationID,
      ShipperAccountID: shipment.shipper?.id,
      ConsigneeAccountID: shipment.consignee?.id,
      AccountBillToID: shipment.accountBillTo?.id,
      AccountSiteID: shipment.accountBillTo?.accountSiteID ?? undefined,
      ServiceID: shipment.service,
      PaymentModeID: shipment.paymentMode,
      DeliveryTypeID: DeliveryType.AT_HOME,
      SRM: !!shipment.pieces?.reduce((acc, p) => acc + p.value, 0),
      TierID: tier.model?.tierID,
      ShippingMethodID: 10,
      PackageTypeID: 10,
      SalesDate: new Date().toISOString(),
      BUCodeSource: shipment.buSource?.code,
      BUCodeConsignee: shipment.buConsignee?.code,
      IsToPickup: shipment.isToPickup,
    });

    if (responseItems.didError || !responseItems.model) {
      setLoadingOptions(false);
      alertService.error("No se pudieron cargar los servicios adicionales");
      return;
    }

    const allItems = responseItems.model.concat(
      shipment.items
        .filter((item) => !responseItems.model?.find((i) => i.id === item.id))
        .map((item) => ({ ...item, mandatory: false }))
    );

    const responseRates = await getShipmentRates(
      shipment.service ?? ShipmentService.STANDARD,
      shipment.paymentMode ?? PaymentMode.COD,
      DeliveryType.AT_HOME,
      shipment.buSource?.code ?? "",
      shipment.buConsignee?.code ?? "",
      shipment.accountBillTo?.id,
      businessUnits.find((bu) => bu.code === shipment.buSource?.code!)!
        .location,
      consigneeAddress,
      allItems.filter(
        (item) => !item.isItemBase && !!item.isItemShipment
      ),
      shipment.pieces,
      shipment.pieces.reduce((acc, p) => acc + p.value, 0) > 0,
      new Date().toISOString(),
      applicationID,
      shipment.shipper.id ?? undefined
    );
    setDeliveryDistance(responseRates.model?.distance);

    if (responseRates.didError || responseRates.model === null) {
      setLoadingOptions(false);
      alertService.error("No se pudieron cargar los servicios adicionales");
      return;
    }

    setAllItems(
      responseRates.model.items
        .filter(
          (item) =>
            (!!item.rate.value || item.id === "21") &&
            item.id !== "26" &&
            item.id !== "6000" &&
            item.id !== "6010"
        )
        .sort((a, b) => a.order - b.order)
    );
    setItems((items) =>
      items.map((item) => {
        const shipmentItem = shipment.items.find((i) => i.id === item.id);
        const responseItem = responseRates.model?.items.find(
          (i) => i.id === item.id
        );

        if (responseItem) return responseItem;
        if (shipmentItem)
          return {
            ...shipmentItem,
            mandatory: false,
          };
        return item;
      })
    );
    setLoadingOptions(false);
  };

  const updateShipmentItems = async () => {
    let deliveryType: DeliveryType = shipment.deliveryType;
    if (
      !shipment.items.some((item) => item.id === "21") &&
      items.some((item) => item.id === "21")
    ) {
      deliveryType = DeliveryType.AT_HOME;
    } else if (
      shipment.items.some((item) => item.id === "21") &&
      !items.some((item) => item.id === "21")
    ) {
      deliveryType = DeliveryType.AT_OFFICE;
    }

    let request = {
      shipmentNumber: shipment.shipmentNumber,
      consigneeAddress,
      items,
      deliveryType,
    };

    loaderService.start();
    const response = await updateShipment(
      request,
      true,
      false,
      user ?? undefined,
      applicationID
    );

    if (response.didError) {
      loaderService.stop();
      alertService.error("No se pudieron actualizar los servicios adicionales");
      return;
    }

    for (const shipmentNumber of selectedShipmentNumbers) {
      if (shipmentNumber === shipment.shipmentNumber) continue;

      request = {
        shipmentNumber,
        consigneeAddress,
        items,
        deliveryType,
      };
      const response = await updateShipment(
        request,
        true,
        false,
        user ?? undefined,
        applicationID
      );

      if (response.didError) {
        loaderService.stop();
        alertService.error(
          "No se pudieron actualizar los servicios adicionales"
        );
        return;
      }
    }

    loaderService.stop();
    alertService.success("Servicios adicionales actualizados correctamente");
    setOpen(false);
    onRefresh();
  };

  useEffect(() => {
    setItems(shipment.items);
  }, [shipment.items, open]);

  useEffect(() => {
    setLocationError("");
    getAllShipmentRates();
  }, [shipment, open, consigneeAddress]);

  useEffect(() => {
    setConsigneeAddress(shipment.consigneeAddress);
    setDeliveryDistance(shipment.deliveryDistance);
  }, [shipment.consigneeAddress, shipment.deliveryDistance]);

  return (
    <Modal openModal={open} setOpenModal={setOpen} className="min-w-[40rem]">
      <h2 className="text-lg font-semibold mb-6">
        Gestionar servicios adicionales de la guía{" "}
        <span className="text-indigo-600">{shipment.shipmentNumber}</span>
      </h2>

      <table className="table-auto w-full mb-4">
        <thead>
          <tr>
            <th className="text-left px-4 py-2 font-semibold text-xs">
              SERVICIO
            </th>
            <th className="text-left px-4 py-2 font-semibold text-xs">
              DETALLES
            </th>
            <th className="text-right px-4 py-2 font-semibold text-xs">
              COSTO
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, i) => {
            const cost = item.rate.isPercentage
              ? (item.rate.value * absoluteTotal) / 100
              : item.rate.value;

            return (
              <tr key={i} className={`${i % 2 === 0 && "bg-gray-50"}`}>
                <td className="text-xs px-4 py-4 font-semibold truncate">
                  <div className="flex items-center gap-2">
                    <div
                      onClick={() =>
                        setItems(items.filter((i) => i.id !== item.id))
                      }
                      className={classNames(
                        "rounded-full bg-red-100 hover:bg-red-200 hover:cursor-pointer p-2 w-fit",
                        shipment.items.some((i) => i.id === item.id) &&
                          item.id !== "21" &&
                          "hidden"
                      )}
                    >
                      <TrashIcon
                        className="h-5 w-5 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    {item.name}
                  </div>
                </td>
                <td className="text-xs px-4 py-4 font-semibold text-gray-600 truncate">
                  <p
                    className={classNames(
                      !item.name.includes("Flete") && "hidden"
                    )}
                  >
                    Zona Tarifaria: {item.rate.tierName}
                  </p>
                  <p
                    className={classNames(
                      (deliveryDistance === undefined || item.id !== "21") &&
                        "hidden"
                    )}
                  >
                    Distancia: {deliveryDistance?.toFixed(2)} km
                  </p>
                  <p
                    className={classNames(
                      (!shipment.declaredValue || item.id !== "20") && "hidden"
                    )}
                  >
                    Valor declarado:{" "}
                    {formatAmount(+(shipment.declaredValue ?? 0))} $
                  </p>
                </td>
                <td className="text-right text-xs px-4 py-4 font-semibold truncate">
                  <div className="flex flex-col items-end">
                    <p className="text-xs font-semibold text-gray-600">
                      {currencyExchangeText(cost, exchanges, "USD")}
                    </p>
                    <p className="text-xs text-gray-400 ">
                      {currencyExchangeText(cost, exchanges, "BS")}
                    </p>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {showMap && (
        <div className="flex flex-col gap-2 mt-4 mb-8">
          <p className="font-semibold">Dirección de entrega</p>
          <AddressSearch
            businessUnits={businessUnits}
            selected={consigneeAddress}
            region={AutocompleteRegion.TEALCA}
            error={locationError}
            setSelectedLocation={(location, buConsignee) => {
              if (buConsignee?.code !== shipment.buConsignee?.code) {
                setLocationError(
                  "La dirección no pertenece a la unidad de negocio de destino"
                );
                return;
              }

              setConsigneeAddress(location);
              setLocationError("");
            }}
          />
        </div>
      )}

      <div className="w-full relative">
        {loadingOptions && (
          <div className="absolute top-0 right-0">
            <LoadingIcon size="1.5rem" />
          </div>
        )}

        <FormSelect
          containerClassName="rounded"
          name="optional-item"
          label="Agregar servicio"
          options={optionalItems}
          optionString={(item) => item.name}
          onSelectOption={(item) => setItems([...items, item])}
        />
      </div>

      <div className="flex items-center justify-between w-full mt-10">
        <SecondaryButton
          disabled={loading}
          className="w-40"
          onClick={() => setOpen(false)}
        >
          Cancelar
        </SecondaryButton>

        <PrimaryButton
          disabled={
            loading ||
            (showMap && consigneeAddress === shipment.consigneeAddress)
          }
          className="w-40"
          onClick={updateShipmentItems}
        >
          Guardar
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default OptionalShipmentItems;
